import styled, { css } from 'styled-components';
import underline from '../../assets/img/blocks/homeHero/underline.svg';

const StyledHeading = styled.div`
    font-family: var(--font-heading);
    color: ${({$primary}) => $primary ? 'var(--color-primary)' : 'var(--color-heading)'};
    line-height: 1.4;
    ${({$center}) => $center && "text-align: center;"};
    
    h1 {
        margin: ${({$noMargin}) => $noMargin ? '0' : '16px 0'};
        font-size: var(--fs-h1);
        font-weight: var(--fw-bold);
        
        span {
            position: relative;
            user-select: unset;
            color: var(--color-primary);
            white-space: nowrap;
            
            &:after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: -5px;
                right: -5px;
                height: 14px;
                z-index: -1;
                background-image: url(${underline});
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        @media screen and (max-width: 1024px) {
            
            span {
                &:after {
                    bottom: -10px;
                }
            }
        }
    }
    
    h2 {
        margin: 8px 0;
        font-size: var(--fs-h2);
        font-weight: var(--fw-bold);

        ${props => props.white && `${white}`};
    }
    
    h3 {
        margin: 8px 0;
        font-size: ${props => props.smaller ? 'var(--fs-h3-smaller)' : 'var(--fs-h3)'};
        font-weight: var(--fw-bold);
    }
    
    h4 {
        line-height: 1.25;
        font-size: var(--fs-h4);
        font-weight: var(--fw-bold);

        ${props => props.white && `${white}`};
    }
`;

const white = css`
    color: var(--color-white);
`;

const Heading = ({tag, children, ...props}) => {
    const validTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
    const givenTag = tag ? tag.toLowerCase() : '';

    const Tag = validTags.includes(givenTag) ? givenTag : 'h2';

    return (
        <StyledHeading {...props}>
            <Tag>{children}</Tag>
        </StyledHeading>
    )
}

export default Heading

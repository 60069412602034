import styled from "styled-components";
import Container from "../../components/Container/Container";

export const StyledContainer = styled(Container)`
    max-width: 768px;
`;

export const Section = styled.section`
    margin: 40px 0;
`;

export const List = styled.ul`
    list-style-type: disc;
    padding-left: 18px;
`;

export const ListItem = styled.li`
    color: var(--color-text);
    font-size: var(--font-size-default);
    font-weight: var(--fw-medium);
    line-height: 1.5;
`;
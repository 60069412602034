import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ParallaxProvider } from 'react-scroll-parallax';
import PopupLinkProvider from "./contexts/PopupLinkContext";
import './i18n';
import MarketingPopupProvider from "./contexts/MarketingPopupContext";
import BreakpointProvider from "./contexts/BreakpointContext";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root, {rootLoader, shouldNotRevalidateParentOnSearchParamChange} from "./routes/Root/Root";
import ErrorPage from "./routes/ErrorPage/ErrorPage";
import Home, {homeLoader} from "./routes/Home/Home";
import Functionalities, {functionalitiesLoader} from "./routes/Functionalities/Functionalities";
import {FunctionalityPopup, functionalityPopupLoader} from "./routes/FunctionalityPopup/FunctionalityPopup";
import BlogsAndLabs, {blogsAndLabsLoader} from "./routes/BlogsAndLabs/BlogsAndLabs";
import {BlogsAndLabsDetail, blogsAndLabsDetailLoader} from "./routes/BlogsAndLabsDetail/BlogsAndLabsDetail";
import Cases, {casesLoader} from "./routes/Cases/Cases";
import {CasePopup, casePopupLoader} from "./routes/CasePopup/CasePopup";
import Pricing, {pricingLoader} from "./routes/Pricing/Pricing";
import About, {aboutLoader} from "./routes/About/About";
import {Network, networkLoader} from "./routes/TMSQRNetwork/TMSQRNetwork";
import Contact, {contactLoader} from "./routes/Contact/Contact";
import Policy, {policyLoader} from "./routes/Policy/Policy";
import {oldUrls, redirectPermanently} from "./helpers/redirectPermanently";
import {langCheckLoader} from "./routes/LangCheck/LangCheck";
import PolicyApp from "./routes/PolicyApp/PolicyApp";

const router = createBrowserRouter([
    {
        path: '/:lang?',
        element: <Root />,
        loader: rootLoader,
        shouldRevalidate: shouldNotRevalidateParentOnSearchParamChange,
        id: "root",
        errorElement: <ErrorPage />,
        children: [
            {
                loader: langCheckLoader,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <Home />,
                        loader: homeLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'functionalities',
                        element: <Functionalities />,
                        loader: functionalitiesLoader,
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: ':slug',
                                element: <FunctionalityPopup />,
                                loader: functionalityPopupLoader,
                            },
                        ],
                    },
                    {
                        path: 'blog',
                        element: <BlogsAndLabs />,
                        loader: blogsAndLabsLoader,
                        shouldRevalidate: shouldNotRevalidateParentOnSearchParamChange,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'blog/:slug',
                        element: <BlogsAndLabsDetail />,
                        loader: blogsAndLabsDetailLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'cases',
                        element: <Cases />,
                        loader: casesLoader,
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: ':slug',
                                element: <CasePopup />,
                                loader: casePopupLoader,
                            },
                        ],
                    },
                    {
                        path: 'pricing/:pricingPageType?',
                        element: <Pricing />,
                        loader: pricingLoader,
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: ':slug',
                                element: <FunctionalityPopup />,
                                loader: functionalityPopupLoader,
                            },
                        ],
                    },
                    {
                        path: 'about',
                        element: <About />,
                        loader: aboutLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'tmsqr-network',
                        element: <Network />,
                        loader: networkLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'contact-us',
                        element: <Contact />,
                        loader: contactLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'terms-and-conditions',
                        element: <Policy />,
                        loader: policyLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'privacy-policy',
                        element: <Policy />,
                        loader: policyLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'privacy-policy-app',
                        element: <PolicyApp />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'cookies',
                        element: <Policy />,
                        loader: policyLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'app/:slug',
                        element: <Policy />,
                        loader: policyLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: '*',
                        element: <ErrorPage />,
                    },
                ],
            },
        ],
    },
    ...oldUrls.map(item => (
        {
            path: item.oldPath,
            loader: () => {
                return redirectPermanently(item.newPath)
            },
            errorElement: <ErrorPage />,
        }
    )),
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        {/*<React.StrictMode>*/}
            <HelmetProvider>
                <ParallaxProvider>
                    <MarketingPopupProvider>
                        <PopupLinkProvider>
                            <BreakpointProvider>
                                <RouterProvider router={router} />
                            </BreakpointProvider>
                        </PopupLinkProvider>
                    </MarketingPopupProvider>
                </ParallaxProvider>
            </HelmetProvider>
        {/*</React.StrictMode>*/}
    </>
);
import TextHero from "../../blocks/TextHero/TextHero";
import React from "react";
import {Seo} from "../../components/Seo/Seo";
import * as S from "./PolicyApp.elements";
import Wrapper from "../../components/Wrapper/Wrapper";
import Heading from "../../components/Heading/Heading";
import Text from "../../components/Text/Text";

const PolicyApp = () => {
    return (
        <>
            <Seo
                title="TMSQR TERMS OF USE and PRIVACY POLICY"
                slug="/privacy-policy-app"
            />

            <TextHero
                title="TMSQR TERMS OF USE and PRIVACY POLICY"
                text="<p>We are excited that you are interested in TMSQR. We appreciate that you take notice of our app privacy policy. If you would like more information, you can always contact us via: <a href='mailto:info@tmsqr.com'>info@tmsqr.com</a>.</p>"
            />

            <Wrapper last="true" $exclude="top">
                <S.StyledContainer>
                    <S.Section>
                        <Text>Welcome to TMSQR!</Text>
                        <Text>These Terms of Use (or <u>&apos;Terms&apos;</u>) apply to your use of the TMSQR application (or <u>&apos;Services&apos;</u>). When you create a TMSQR account or use the Services, you agree to these Terms.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">1. Contact Information</Heading>
                        <Text>The provider of the Services and your contractual counterparty is TimeSquare B.V. (hereinafter also &apos;we&apos; or &apos;us&apos;). If you have any questions about these Terms, please contact us at:</Text>
                        <Text>TimeSquare B.V. (KvK 62546473)<br/>Dorus Rijkersweg 15<br/>2315 WC Leiden<br/>The Netherlands<br/>info@tmsqr.com</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">2. About our Services</Heading>
                        <Text>The TMSQR application is an app for festivals, artists and visitors. Through the Services, event organizers, artists and visitors can connect and communicate with each other. We may modify the Services from time to time and introduce new features or related services. To enable new features and improved functionality, you agree to download and install updates for our Services.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">3. Account Information</Heading>
                        <Text>To access the Services, or parts of the Services, you may need to create an account. You must create an account with accurate and complete information. You are responsible for keeping your account information, password, and other security codes confidential to prevent unauthorized access to your account. You must take all necessary steps to secure and keep your password and other security codes confidential. You are responsible for all activities conducted under your account. We will never ask you to share your password or other personal security code with us or a third party.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">4. Minimum Age</Heading>
                        <Text>Are you under 13 years old? Then the Services are not intended for you, and you may not use the Services. Users under 18 years old must have permission from a parent or guardian.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">5. Acceptable Use</Heading>
                        <Text>You agree to use the Services only for lawful purposes and in accordance with the law. You may not post or refer to content that is illegal, obscene, threatening, discriminatory, or offensive. You may not post or refer to content that infringes on the privacy of others or any intellectual property rights of others, including copyrights, trademarks, and trade secrets. You also agree not to upload viruses, worms, malware, Trojan horses, or other harmful or destructive content into the Services and not to use the Services for sending spam or other unlawful activities.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">6. Intellectual Property Rights</Heading>
                        <Text>All intellectual property rights to the Services, including the software, graphical user interface (GUI), and data files, are exclusively owned by us or our licensors. You retain all ownership rights to the content you post on the Services. By posting content, you grant us a non-exclusive, royalty-free, worldwide license to use, copy, distribute, and display this content.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">7. Privacy</Heading>
                        <Text>To provide the Services, we need to collect and use data about you. Our Privacy Policy explains how we collect, use, and share data. Our Privacy Policy is attached to these Terms as an <u>Appendix</u>.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">8. Modifying Content and Terminating Access</Heading>
                        <Text>We may remove or restrict content or information, or terminate or restrict your access to the Services if we believe your use violates these Terms, if we are legally required to do so, or if the content or information is reasonably harmful to us or other users. If you believe that content on the Services is unlawful, you may submit a report to us via the report function in the Services (if applicable) or via email at info@tmsqr.com.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">9. Indemnification</Heading>
                        <Text>You indemnify us against any claim from a third party based on the assertion that content you posted or shared infringes on any right of that third party, including privacy rights and intellectual property rights.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">10. Disclaimer</Heading>
                        <Text>We strive to provide the Services in a professional and careful manner. However, we do not guarantee that the Services will be error-free and uninterrupted. We take reasonable technical and organizational security measures to secure data center facilities, servers, network equipment, storage media, and host software systems under our control and used to provide the Services. However, we do not guarantee that information security will be effective under all circumstances.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">11. Limitation of Liability</Heading>
                        <Text>We are not liable for damage resulting from cyber-attacks and data leaks. Our total liability for damages, on any legal basis, is limited to EUR 100 per event (where a series of related events counts as one event). This exclusion and limitation of liability does not apply in cases where exclusion or limitation of liability is not permitted by law. In such cases, our liability is limited to the maximum extent permitted by applicable law.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">12. Assignment</Heading>
                        <Text>You are not allowed to assign your rights or obligations under this agreement without our consent. Our rights and obligations may be assigned to others. This may happen, for example, if our ownership changes (such as in a merger, acquisition, or sale of assets).</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">13. Governing Law and Disputes</Heading>
                        <Text>These Terms are governed by and interpreted in accordance with Dutch law. All disputes related to these Terms will be submitted to the competent court in the Netherlands.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">14. Changes</Heading>
                        <Text>We reserve the right to change these Terms (incl. the Privacy Policy) at any time. We will make reasonable efforts to notify you of any changes and give you the opportunity to review the changes before they take effect. If you continue to use the Services after the changes take effect, you are bound by the updated Terms. If you do not agree with the updated Terms, you can delete your account.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3" $center>APPENDIX: PRIVACY POLICY</Heading>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">Personal Data</Heading>
                        <Text>We process the personal data you submit to the Services when you create an account and when you use the Services, for example when you post messages in the chat function, or when you contact us directly. Additionally, your personal data may be processed through the Services when other users upload or share content that includes your personal data.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">Purposes</Heading>
                        <Text>We process your personal data for the following purposes:</Text>
                        <S.List>
                            <S.ListItem><strong>Providing the Services.</strong> We process your data to enable you to use the Services. The legal basis is performance of a contract.</S.ListItem>
                            <S.ListItem><strong>Product Development.</strong> We continuously aim to improve our Services. Information we collect from you helps us learn how best to do this. In this context, we collect data about the use of the Services, problem reports, and surveys. The legal basis is our legitimate interest.</S.ListItem>
                            <S.ListItem><strong>Security.</strong> We use data to detect and address harmful or unlawful use of the Services. The legal basis is our and your legitimate interest.</S.ListItem>
                            <S.ListItem><strong>Contact.</strong> We use data to respond to your question or process your feedback. The legal basis is legitimate interest or consent.</S.ListItem>
                            <S.ListItem><strong>Marketing.</strong> We may use your contact details to inform you about the development of our Services and special offers and promotions. If you no longer wish to receive this information, you can let us know by contacting us or using the unsubscribe option in the relevant newsletter or mailing. The legal basis is legitimate interest (when you are a registered user) or consent.</S.ListItem>
                            <S.ListItem><strong>Compliance.</strong> We may process your data to comply with specific legal obligations or orders from competent authorities, such as a request for information from the police in a criminal investigation. The legal basis is compliance with a legal obligation.</S.ListItem>
                        </S.List>
                        <Text>We retain information as long as we need it to provide our Services, comply with legal obligations, or protect our or others' interests. We determine on a case-by-case basis how long we need information and do not retain data longer than necessary for the purposes mentioned above.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">No Automated Decision-Making</Heading>
                        <Text>We do not use your personal data to make automated decisions that have legal consequences for you or otherwise significantly affect you.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">Disclosure to Third Parties</Heading>
                        <Text>For our services, we use third-party services, such as hosting service providers. In this context, personal data is provided to these third parties. We have agreements with these third parties to ensure the careful handling of your personal data. In exceptional cases, we may disclose your personal data to third parties based on a legal obligation. Think of the government in criminal investigations.</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">Transfer of Data Outside the EEA</Heading>
                        <Text>We generally process your personal data within the European Economic Area (EEA). When we share personal data with organizations in countries outside the EEA, we always take the necessary measures to protect this data. We rely, among other things, on model clauses established by the European Commission (the Standard Contractual Clauses).</Text>
                    </S.Section>
                    <S.Section>
                        <Heading tag="h3">Your Rights</Heading>
                        <Text>Under data protection legislation, you have several rights regarding the processing of your personal data. You have the right to:</Text>
                        <S.List>
                            <S.ListItem>Request access, rectification, or deletion of your personal data or restriction of the processing of your personal data;</S.ListItem>
                            <S.ListItem>Object to a particular processing;</S.ListItem>
                            <S.ListItem>Request us to transfer your data to a third party;</S.ListItem>
                            <S.ListItem>Withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.</S.ListItem>
                        </S.List>
                        <Text>Questions about this privacy policy or a request regarding the processing of your personal data can be sent to: info@tmsqr.com.</Text>
                        <Text>If you have a complaint about the processing of your personal data, please let us know. If we cannot resolve it together, you always have the right to file a complaint with a supervisory authority.</Text>
                        <Text>Version: [1 Jan 2025]</Text>
                    </S.Section>
                </S.StyledContainer>
            </Wrapper>
        </>
    );
}

export default PolicyApp
